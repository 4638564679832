<template>
  <div>
    <el-card style="margin: 20px">
      <el-row :gutter="100">
        <el-col :span="1">
          <el-image
              style="width: 100px; height: 100px"
              :src="infoData.logo"
              :preview-src-list="[infoData.logo]">
          </el-image>
          <!--          <img :src="infoData.logo" style="width: 80px;height: 80px"/>-->
        </el-col>
        <el-col style="margin-left: 20px" :span="7">
          <h1 class="title" @click='init()'>
            {{ infoData.name }}
          </h1>
          <h4>{{ infoData.company_name }}</h4>
          <span>品牌备注：{{ infoData.remark || '暂无' }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-card style="margin: 20px">
      <div slot="header" class="clearfix">
        <span>公司信息</span>
      </div>
      <el-table :data="customer" ref="multipleTable" row-key="key"
                width="800"   @cell-dblclick="handelCopy"
                default-expand-all border>
        <el-table-column align="center" label="序号" type="index" width="80"></el-table-column>
        <el-table-column align="center" prop="customers.name" label="公司名">
        </el-table-column>
        <el-table-column align="center" prop="contact_user" label="联系人">
        </el-table-column>
        <el-table-column align="center" prop="contact_info" label="联系方式">
        </el-table-column>
        <el-table-column align="center" prop="delivery_info" width="300" label="物流详情">
        </el-table-column>

        <el-table-column align="center" prop="submit_name" label="录入人"></el-table-column>
        <el-table-column align="center" prop="created_at" label="录入时间"></el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin: 20px">
      <div slot="header" class="clearfix">
        <span>合同资料</span>
      </div>
      <el-table :data="contract" ref="multipleTable"
                default-expand-all
                border>
        <el-table-column align="center" show-overflow-tooltip label="公司名" width="200" prop="customers[0].name">
        </el-table-column>
        <el-table-column align="center" label="合同名" min-width="120"
                         prop="name" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="due_date" label="合同有效截止日期"></el-table-column>
        <el-table-column align="center" prop="created_at" label="上传日期"></el-table-column>
        <el-table-column align="center" prop="submit_name" label="上传人"></el-table-column>
        <el-table-column align="center" prop="remark" label="合同备注"></el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin: 20px">
      <div slot="header" class="clearfix">
        <span @click='getList'>关联产品</span>
      </div>
      <el-table :data="product" ref="multipleTable" row-key="id"
                width="800" highlight-current-row
                border>
        <el-table-column align="center" label="序号" type="index" width="60"></el-table-column>
        <el-table-column align="center" prop="created_at" show-overflow-tooltip label="录入时间"
                         width="100"></el-table-column>
        <el-table-column align="center" prop="customer.name" show-overflow-tooltip label="所属公司"
        ></el-table-column>
        <el-table-column align="center" prop="customer_name" show-overflow-tooltip label="产品图" width="120">
          <template slot-scope="{row}">
            <el-image
                style="width: 40px; height: 40px"
                :src="row.cover?.full_path"
                :preview-src-list="[row.cover?.full_path]">
              <div slot="error" class="image-slot">
                暂无图片
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" width="80" show-overflow-tooltip prop="category.display_name"
                         label="类目">
        </el-table-column>

        <el-table-column align="center" width="130" show-overflow-tooltip prop="spec_name" label="规格">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip prop="title" label="商品名">
        </el-table-column>
        <el-table-column align="center" width="100" show-overflow-tooltip prop="mode.display_name" label="合作方式">
        </el-table-column>
        <el-table-column align="center" prop="brand_name" label="日常价/直播价">
          <template slot-scope="{row}">
            {{ row?.price || '-' }} / {{ row?.gift || '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" label="赠品/其他">
          <template slot-scope="{row}">
            {{ row?.gift||'-' }}
          </template>
        </el-table-column>
        <el-table-column width="150" align="center" label="线上/线下佣金(%)">
          <template slot-scope="{row}">
            {{ row?.online_cmn || '-' }} / {{ row?.offline_cmn || '-' }}
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" prop="leader_name" label="负责人">
        </el-table-column>
        <el-table-column align="center" prop="remark" label="产品备注">
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span='24'>
          <Pagination :limit.sync='pagingData.page_size'
                      :page.sync='pagingData.current_page'
                      :total='pagingData.total'
                      @pagination='getList'/>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'infoPage',
  data() {
    return {
      infoData: {},
      id: '',
      isLoadind: false,
      customer: [],
      contract: [],
      product: [],
      pagingData: {
        page_size: 5,
        current_page: 1,
        total: 0
      }
    }
  },
  methods: {
    handelCopy(row, column) {
      const oInput = document.createElement('textarea')
      oInput.value = row[column.property]
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;
      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({ message: `${column.label} 信息复制成功`, type: 'success' })
      oInput.remove()
    },
    init(){
      this.getInfo()
      this.getList()
    },
    async getList() {
      //   getBrandProduct
      let searchCondition={}
      Object.assign(searchCondition, this.pagingData, { brand_id: this.id })

      let { list, page_info } = await this.$api.getBrandProduct(searchCondition)
      this.pagingData.current_page = page_info.current_page
      this.pagingData.page_size = page_info.page_size
      this.pagingData.total = page_info.total
      this.product = list
    },
    async getInfo() {
      this.isLoading = true
      this.infoData = await this.$api.infoBrandList({ brand_id: this.id })
      this.isLoading = false
      //   getBrandCustomer
      let customerData = await this.$api.getBrandCustomer({ brand_id: this.id })
      // customerData.forEach((i) => {
      //   if (i?.contacts?.length === 1) {
      //     i.key = Math.random()
      //     i.hasChildren = true
      //     i.contact_user = i.contacts[0].contact_user
      //     i.contact_info = i.contacts[0].contact_info
      //   } else if (i?.contacts?.length > 1) {
      //     i.key = Math.random()
      //     i?.contacts.forEach((item) => {
      //       item.name = i.name
      //       item.key = Math.random()
      //     })
      //     i.submit_name = null
      //   }
      // })
      this.customer = customerData
      let data = await this.$api.getBrandContract({ brand_id: this.id })
      this.contract = data
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.getInfo()
    this.getList()
  }
}
</script>

<style scoped>
.title {
  color: #ff3176;
  font-size: 30px;
  padding: 0;
  margin: 0 0 12px;

}

</style>
